(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("firebase/database"), require("monaco-editor"));
	else if(typeof define === 'function' && define.amd)
		define(["firebase/database", "monaco-editor"], factory);
	else if(typeof exports === 'object')
		exports["@otjs/firebase-monaco"] = factory(require("firebase/database"), require("monaco-editor"));
	else
		root["@otjs/firebase-monaco"] = factory(root["firebase/database"], root["monaco-editor"]);
})(this, function(__WEBPACK_EXTERNAL_MODULE__877__, __WEBPACK_EXTERNAL_MODULE__643__) {
return 