(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@otjs/state-machine"), require("mitt"));
	else if(typeof define === 'function' && define.amd)
		define(["@otjs/state-machine", "mitt"], factory);
	else if(typeof exports === 'object')
		exports["@otjs/plaintext-editor"] = factory(require("@otjs/state-machine"), require("mitt"));
	else
		root["@otjs/plaintext-editor"] = factory(root["@otjs/state-machine"], root["mitt"]);
})(this, function(__WEBPACK_EXTERNAL_MODULE__270__, __WEBPACK_EXTERNAL_MODULE__56__) {
return 